/**
 * Copyright (C) Sitevision AB 2002-2021, all rights reserved
 *
 * @author robin
 */
import $ from '@sv/jquery';

const sizeFieldCache = {};

$('.sv-has-error').each(function () {
  const $this = $(this);
  const formElSelector = 'input, textarea, select';
  const $formEl = $this.is(formElSelector) ? $this : $this.find(formElSelector);
  $formEl.attr('aria-invalid', 'true');
  $this.on('change keyup', function () {
    $this.removeClass('sv-has-error');
    $formEl.removeAttr('aria-invalid');
    $this.off();
  });
});

//----- Max characters in text field -----

const countdownCharactersLeft = function ($target) {
  const value = $target.val(),
    text = value ? value.trim() : '',
    sizeField = sizeFieldCache[$target.attr('id')],
    size = sizeField.initialSize - text.length; //Text is trimmed server side

  if (size < 0) {
    sizeField.$el.addClass('sv-textfield-limit-exceeded');
  } else {
    sizeField.$el.removeClass('sv-textfield-limit-exceeded');
  }

  sizeField.$el.text(size);
};

$('.sv-form-textfield.sv-limit-characters').each(function () {
  const $this = $(this),
    sizeField = $this.parent().find('.sv-textfield-input-size'),
    id = $this.attr('id');

  sizeFieldCache[id] = {
    $el: sizeField,
    initialSize: sizeField.text(),
  };

  $this.on('keyup', function (e) {
    countdownCharactersLeft($(e.currentTarget));
  });

  if ($this.val().length) {
    countdownCharactersLeft($this);
  }
});

$('form[data-sv-single-click]').one('submit', function () {
  $(this).find('input[type=submit]').prop('disabled', true);
});

//----- Required fields custom validation -----

const setValueMissingMessage = ($el, valueMissing, message) => {
  $el
    .removeAttr('aria-invalid')
    .closest($el.data('sv-form-req-error-selector') || 'div')
    .removeClass('sv-has-error')
    .find('.sv-form-field-message')
    .remove();

  if (valueMissing) {
    $el[0].setCustomValidity(message);
    $el
      .attr('aria-invalid', 'true')
      .closest($el.data('sv-form-req-error-selector') || 'div')
      .addClass('sv-has-error')
      .append(`<div class="sv-form-field-message" style="margin-top: 1px">
                <span id="sv-field-message-${$el[0].id}"
                role="alert"
                style="vertical-align:top"
                class="normal">
                ${message}</span>
                <br></div>`);
  } else {
    $el[0].setCustomValidity('');
  }
};

const requiredListener = (e) => {
  let allValid = true;

  $(e.target)
    .closest('form')
    .find('[data-sv-form-req-invalid]')
    .each(function (i, el) {
      const $el = $(el);
      const validityState = el.validity;

      if (validityState.valueMissing) {
        allValid = false;
      }

      setValueMissingMessage(
        $el,
        validityState.valueMissing,
        $el.attr('data-sv-form-req-invalid')
      );
    });

  return allValid;
};

$('[data-sv-form-req-invalid]').each((i, el) => {
  const $el = $(el);
  const $form = $el.closest('form');
  $el.on('input', (e) => {
    const $el = $(e.target);
    setValueMissingMessage(
      $el,
      e.target.validity.valueMissing,
      $el.attr('data-sv-form-req-invalid')
    );
  });
  if (!$form.data('sv-form-req-invalid-listener')) {
    $form
      .on('submit', requiredListener)
      .on('click', '[type=submit]', requiredListener)
      .attr('novalidate', 'novalidate')
      .data('sv-form-req-invalid-listener', true);
  }
});

//----- Visible participants (Frågeformulär/Deltagare) -----

const setParticipantsVisible = ($el, mandatory) => {
  const visibleRowCount = parseInt($el.val(), 10);
  const $rows = $el.closest('table').find('[data-sv-form-participants-row]');
  const renameAttribute = ($el, oldAttr, newAttr) => {
    $el.attr(newAttr, $el.attr(oldAttr));
    $el.removeAttr(oldAttr);
  };

  $rows.slice(0, visibleRowCount).show();
  $rows.slice(visibleRowCount).hide();

  if (mandatory) {
    $rows
      .slice(0, visibleRowCount)
      .find('input')
      .each((i, el) => {
        const $el = $(el);
        $el.prop('required', true);
        renameAttribute(
          $el,
          'data-sv-form-req-invalid-disabled',
          'data-sv-form-req-invalid'
        );
      });
    $rows
      .slice(visibleRowCount)
      .find('input')
      .each((i, el) => {
        const $el = $(el);
        $el.prop('required', false);
        renameAttribute(
          $el,
          'data-sv-form-req-invalid',
          'data-sv-form-req-invalid-disabled'
        );
      });
  }
};

$('[data-sv-form-participants]').each((i, el) => {
  const $el = $(el);
  const mandatory = $el.data('sv-form-participants');

  $el.on('change', () => {
    setParticipantsVisible($el, mandatory);
  });

  setParticipantsVisible($el, mandatory);
});
