/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import Backbone from '@sv/backbone';
import $ from '@sv/jquery';
import { ObjectUtil as objectUtil } from '@sv/util';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';

var SharePointItem = Backbone.Model.extend({
  getViewUrl: function () {
    return this.get('url');
  },

  isFolder: function () {
    return this.get('isFolder');
  },
});

var SharePointItemCollection = Backbone.Collection.extend({
  model: SharePointItem,

  initialize: function (models, options) {
    this.portletId = options.portletId;
    this.currentFolderProps = options.currentFolderProps;
  },

  url: function () {
    return getPortletResourceUri(this.portletId, 'files');
  },

  setCurrentFolderProps: function (folderProps) {
    this.currentFolderProps = folderProps;
  },

  getCurrentFolderProps: function () {
    return this.currentFolderProps;
  },

  parse: function (data) {
    this.setCurrentFolderProps(data.currentFolderProps);
    return data.items;
  },
});

var SharePointItemView = Backbone.View.extend({
  tagName: 'tr',

  events: {
    'click [data-fetch-item]': 'fetchItem',
  },

  initialize: function () {
    this.template = this.options.template;
    this.model.set(
      'fontClass',
      this.model.isFolder()
        ? this.options.folderFontClass
        : this.options.fileFontClass
    );
    this.portletView = this.options.portletView;
  },

  render: function () {
    this.$el.html(this.template(this.model.toJSON(), this.model));
    return this;
  },

  fetchItem: function (e) {
    if (this.model.isFolder()) {
      e.preventDefault();
      this.portletView.fetchFolder(this.model);
    }
  },
});

var SharePointItemCollectionView = Backbone.View.extend({
  initialize: function () {
    this.itemTemplate = this.options.itemTemplate;
    this.fileFontClass = this.options.fileFontClass;
    this.folderFontClass = this.options.folderFontClass;
    this.portletView = null;
  },

  render: function () {
    this.clearTable();

    this.collection.each(function (item) {
      this.appendOne(item);
    }, this);
  },

  appendOne: function (item) {
    var itemView = new SharePointItemView({
      model: item,
      template: this.itemTemplate,
      portletView: this.portletView,
      fileFontClass: this.fileFontClass,
      folderFontClass: this.folderFontClass,
    });

    this.$el.append(itemView.render().el);
  },

  clearTable: function () {
    this.$el.empty();
  },
});

var SharePointBreadcrumb = SharePointItem.extend({
  isFolder: function () {
    return true;
  },
});

var SharePointBreadcrumbView = Backbone.View.extend({
  tagName: 'li',

  className: 'env-breadcrumb__item',

  events: {
    'click [data-fetch-folder]': 'fetchFolder',
  },

  initialize: function () {
    this.template = this.options.template;
    this.portletView = this.options.portletView;
    this.model.set('isActive', this.options.isActive);
  },

  render: function () {
    this.$el.html(this.template(this.model.toJSON(), this.model));
    return this;
  },

  fetchFolder: function (e) {
    e.preventDefault();
    this.portletView.fetchFolder(this.model);
  },
});

var SharePointBreadcrumbCollectionView = Backbone.View.extend({
  initialize: function () {
    this.breadcrumbTemplate = this.options.breadcrumbTemplate;
    this.portletView = null;
  },

  render: function () {
    this.clearList();

    this.collection.each(function (item, index) {
      this.appendOne(item, index === this.collection.length - 1);
    }, this);
  },

  appendOne: function (item, isActive) {
    var breadcrumbView = new SharePointBreadcrumbView({
      model: item,
      template: this.breadcrumbTemplate,
      portletView: this.portletView,
      isActive: isActive,
    });

    this.$el.append(breadcrumbView.render().el);
  },

  clearList: function () {
    this.$el.empty();
  },
});

var SharePointPortletView = Backbone.View.extend({
  initialize: function () {
    this.breadcrumbCollectionView = this.options.breadcrumbCollectionView;
    this.breadcrumbCollection = this.breadcrumbCollectionView.collection;
    this.itemCollectionView = this.options.itemCollectionView;
    this.itemCollection = this.itemCollectionView.collection;
    this.breadcrumbCollectionView.portletView = this;
    this.itemCollectionView.portletView = this;
  },

  render: function () {
    this.breadcrumbCollectionView.render();
    this.itemCollectionView.render();
  },

  fetchFolder: function (model) {
    var currentFolderProps = this.itemCollection.getCurrentFolderProps();

    this.itemCollection
      .fetch({
        data: {
          sharePointFolderId: model.id,
          trail: currentFolderProps.trail,
          trailTime: currentFolderProps.trailTime,
        },
        context: this,
      })
      .done(function (response) {
        if (response.unauthorized) {
          window.location.href = model.getViewUrl();
          return;
        }

        this.breadcrumbCollection.reset(response.breadcrumbs);
        this.render();
      });
  },
});

$('.sv-sharepointfile-portlet').each(function () {
  var $portletDiv = $(this),
    portletId = objectUtil.getObjectId($portletDiv.attr('id')),
    $rootDiv = $portletDiv.find('.sv-sharepointfile-root'),
    jsNameSpace = $rootDiv.data('js-namespace'),
    portletSettings = window.sv[jsNameSpace] || [];

  if (portletSettings.length === 0) {
    return;
  }

  var $breadcrumbList = $rootDiv.find('.sv-sharepointfile-breadcrumbs'),
    $fileTableBody = $rootDiv.find('.sv-sharepointfile-files tbody'),
    fileFontClass = $fileTableBody.data('file-font-class'),
    folderFontClass = $fileTableBody.data('folder-font-class'),
    items = portletSettings.items,
    currentFolderProps = portletSettings.currentFolderProps,
    breadcrumbs = portletSettings.breadcrumbs,
    itemTemplate = getTemplate($portletDiv, 'sharepointitem'),
    breadcrumbTemplate = getTemplate($portletDiv, 'sharepointbreadcrumb');

  var breadcrumbCollection = new Backbone.Collection(breadcrumbs, {
    model: SharePointBreadcrumb,
  });

  var breadcrumbCollectionView = new SharePointBreadcrumbCollectionView({
    el: $breadcrumbList,
    collection: breadcrumbCollection,
    breadcrumbTemplate: breadcrumbTemplate,
  });

  var itemCollection = new SharePointItemCollection(items, {
    portletId: portletId,
    currentFolderProps: currentFolderProps,
  });

  var itemCollectionView = new SharePointItemCollectionView({
    el: $fileTableBody,
    collection: itemCollection,
    breadcrumbCollectionView: breadcrumbCollectionView,
    fileFontClass: fileFontClass,
    folderFontClass: folderFontClass,
    itemTemplate: itemTemplate,
  });

  var portletView = new SharePointPortletView({
    breadcrumbCollectionView: breadcrumbCollectionView,
    itemCollectionView: itemCollectionView,
  });

  portletView.render();
});
