/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';

$('.sv-tagcloudresult-portlet').each(() => {
  import(/* webpackChunkName: "search-plugins" */ '../util/searchPlugins');
});

