/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';

$('.sv-imagemap-portlet').each((a, portlet) => {
  const $portlet = $(portlet);

  import(/* webpackChunkName: "imagemap-plugins" */ './plugins').then(() => {
    $portlet.find('.sv-imagemap').rwdImageMaps();
  });
});