/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author sjostrand
 */
import $ from '@sv/jquery';

$('.sv-login-form').each(function () {
  var $form = $(this).find('form'),
    $name = $form.find('[name=name]'),
    $pwd = $form.find('[name=pwd]'),
    $loginError = $form.find('.sv-login-error-message');

  if ($loginError.length) {
    $loginError.trigger('focus');
  } else if ($form.hasClass('sv-login-name-focus')) {
    $name.trigger('focus');
  }

  if ($form.hasClass('sv-login-autocomplete-off')) {
    $form.attr('autocomplete', 'off');
  } else {
    $name.attr('autocomplete', 'username');
    $pwd.attr('autocomplete', 'current-password');
  }
});
