/**
* Copyright (C) SiteVision AB 2002-2020, all rights reserved
*
* @author sjostrand
*/
import $ from '@sv/jquery';
import { doGetWithFade, doGet } from './portletUtil';

$('[data-portlet-lazy-load]').each(function() {
  const $el = $(this),
  id = $el.data('portlet-id'),
  url = $el.data('portlet-url'),
  reload = $el.data('portlet-reload');

  doGetWithFade('#' + id, url);
  if (reload) {
    var timeout = $el.data('portlet-timeout');

    setInterval(function() {
      doGet('#' + id, url);
    }, timeout);
  }
});
