/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author jaime
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import './plugins';

let $dialogContainer;
let $subDialogContainer;

function getDialogContainer() {
  if (!$dialogContainer || $dialogContainer.length === 0) {
    $dialogContainer = $('<div/>');
  }

  return $dialogContainer;
}

function handleSubmit(e) {
  e.preventDefault();
  var $form = getDialogContainer().find('form');
  $.post($form.attr('action'), $form.serialize(), function (aData) {
    closeDialog();
    if (aData.indexOf('http-equiv="Refresh"') !== -1) {
      window.location.href = $('input[name=redirectURL]').attr('value');
    } else if ($(aData).find('div#error-messages').length > 0) {
      showDialog(aData);
    } else {
      showMessageDialog(aData);
    }
  });
}

function closeDialog() {
  $dialogContainer.svDialog('remove');
}

function closeSubDialog() {
  $subDialogContainer.svDialog('close');
}

function showMessageDialog(message) {
  $dialogContainer = getDialogContainer();

  if (!$subDialogContainer) {
    $subDialogContainer = $('<div/>')
      .html(message)
      .svDialog({
        open: true,
        title: $('input[name=windowTitle]').attr('value'),
        buttons: [
          {
            text: $('input[name=closeButtonText]').attr('value'),
            type: 'primary',
            callback: closeSubDialog,
          },
        ],
      });
  } else {
    $subDialogContainer.html(message).svDialog('open');
  }
}

function showDialog(message) {
  $dialogContainer = $('<div/>')
    .html(message)
    .svDialog({
      open: true,
      title: $('input[name=windowTitle]').attr('value'),
      buttons: [
        {
          text: $('input[name=buttonText]').attr('value'),
          type: 'primary',
          callback: handleSubmit,
        },
        {
          text: $('input[name=closeButtonText]').attr('value'),
          type: 'link',
          callback: closeDialog,
        },
      ],
    });
}

function addListeners(id) {
  var $a = $('#' + id);
  $dialogContainer = getDialogContainer();

  $a.on('click', function (e) {
    e.preventDefault();
    $dialogContainer.svDialog({
      open: true,
      url: this.href,
      title: $('input[name=windowTitle]').attr('value'),
      modal: true,
      buttons: [
        {
          text: $('input[name=buttonText]').attr('value'),
          type: 'primary',
          callback: handleSubmit,
        },
        {
          text: $('input[name=closeButtonText]').attr('value'),
          type: 'link',
          callback: closeDialog,
        },
      ],
    });
  });
}

if (sv.tellfriend2 && sv.tellfriend2.length) {
  for (let i = 0; i < sv.tellfriend2.length; i += 1) {
    addListeners(sv.tellfriend2[i]);
  }
}
