/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * helpers related to images
 *
 * @author sjostrand
 */

import sv from '@sv/core';

function createSlideShow(
  textFont,
  interval,
  offset,
  autoHide,
  hideControls,
  hideThumbs
) {
  import(/* webpackChunkName: "imagealbum-plugins" */ './plugins').then(() => {
    doCreateSlideShow(
      textFont,
      interval,
      offset,
      autoHide,
      hideControls,
      hideThumbs
    );
  });
}

function doCreateSlideShow(
  textFont,
  interval,
  offset,
  autoHide,
  hideControls,
  hideThumbs
) {
  var hs = window.hs;
  var lang = sv.i18n.getLanguage();
  if (lang === 'de' || lang === 'no' || lang === 'sv') {
    hs.lang = hs.lang[lang];
  }
  hs.numberingClass = textFont;

  var options = {
    interval: interval,
    repeat: true,
    useControls: !hideControls,
    fixedControls: 'fit',
  };
  if (!hideControls) {
    options.overlayOptions = {
      position: 'bottom right',
      hideOnMouseOut: autoHide,
      offsetY: offset,
      offsetX: -3,
    };
  }
  if (!hideThumbs) {
    options.thumbstrip = {
      position: 'above',
      mode: 'horizontal',
      relativeTo: 'expander',
    };
  }
  // Add the slideshow providing the controlbar and the thumbstrip
  hs.addSlideshow(options);
}

sv.ImageAlbumUtil = {
  createSlideShow: createSlideShow,
};
