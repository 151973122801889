/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
;(function ( $, window, document, undefined ) {

   var pluginName = 'svDialog',
      defaults = {
         cssClasses: {
         },
         open: false,
         modal: true,
         movable: false,
         url: undefined,
         title: 'My fancy dialog',
         closeOnEscape: true
      };

   function getDialogWrapper(modal) {
      var html = '';

      if (modal) {
         html += '<div class="sv-dialog-container">' +
                   '<div class="sv-dialog-backdrop"></div>';
      }

      html +=       '<div class="sv-dialog" role="dialog" aria-hidden="true" aria-labelledby="sv-dialog-title">' +
                       '<div class="sv-dialog-content">' +
                          '<div class="sv-dialog-header">' +
                             '<button class="sv-dialog-close"><span aria-hidden="true">&times;</span></button>' +
                             '<h4 id="sv-dialog-title"></h4>' +
                          '</div>' + // end sv-dialog-header
                          '<div class="sv-dialog-body"></div>' +
                          '<div class="sv-dialog-footer"></div>' +
                       '</div>' + // end sv-dialog-content
                    '</div>'; // end sv-dialog

      if (modal) {
         html += '</div>'; // end sv-dialog-container
      }

      return $(html);
   }

   function Plugin (element, options) {
      this.element = element;
      this.$element = $(element);

      this.options = $.extend( {}, defaults, options );
      this._defaults = defaults;
      this._name = pluginName;
      this.init();
   }

   Plugin.prototype = {
      init: function () {
         this.$dialogWrapper = getDialogWrapper(this.options.modal);

         this.$dialogWrapper
            .find('.sv-dialog-body')
               .append(this.element);

         if (this.options.buttons) {
            this.setupButtons();
         }

         this.bindEvents();

         if (!this.options.modal) {
            this.$dialogWrapper.addClass('sv-dialog-movable');
            this.$dialogWrapper.draggable({
               handle: '.sv-dialog-header',
               containment: 'body',
               distance: 10
            });
         }

         if (this.options.open) {
            this.open();
         }
      },

      open: function() {
         if (this.options.url) {
            $.get(this.options.url).done($.proxy(this.handleRemoteContent, this));
            return;
         }

         this._open();
      },

      handleRemoteContent: function(response) {
         this.$element.html(response);
         this._open();
      },

      _open: function() {
         this.$dialogWrapper.appendTo('body');

         this.setTitle();

         this.setAriaHidden(false);

         this.$dialogWrapper
            .find('.sv-dialog-container')
               .css('height', $(window).height());

         if (this.options.modal) {
            $('body').addClass('sv-dialog-open');
         } else {
            this.positionDialog();
         }

         this.$dialogWrapper.show();
         this.setFocus();

         this.$element.trigger('open:dialog.sv-dialog');
      },

      setTitle: function() {
         this.$dialogWrapper
            .find('#sv-dialog-title')
               .html(this.options.title);
      },

      setAriaHidden: function(hidden) {
         var $dialog = this.options.modal ? this.$dialogWrapper.find('.sv-dialog') : this.$dialogWrapper;
         $dialog.attr('aria-hidden', hidden);
      },

      positionDialog: function() {
         var width = this.$dialogWrapper.outerWidth();
         this.$dialogWrapper.css({
            position: 'fixed',
            top: '30px',
            left: '50%',
            marginLeft: '-' + (window.parseInt(width, 10) / 2) + 'px'
         });
      },

      setupButtons: function() {
         $.each(this.options.buttons, $.proxy(function(index, button) {
            var $button = $('<button class="sv-dialog-btn" />');
            $button
               .text(button.text)
               .on('click', button.callback);

            switch (button.type) {
               case 'primary':
                  $button.addClass('sv-dialog-btn-primary');
                  break;
               case 'link':
                  $button.addClass('sv-dialog-btn-link');
                  break;
            }

            $button.appendTo(this.$dialogWrapper.find('.sv-dialog-footer'));
         }, this));
      },

      close: function() {
         this.$dialogWrapper.hide();
         this.setAriaHidden(true);

         $('body').removeClass('sv-dialog-open');
      },

      remove: function() {
         $('body').removeClass('sv-dialog-open');
         this.$dialogWrapper.remove();
      },

      setFocus: function() {
         var $inputs = this.$element.find('input:not([type=hidden], .svhidden, .sv-hidden),select');
         if ($inputs.length) {
            $inputs.first().trigger('focus');
         }
      },

      enableSubmitButton: function() {
         this.$dialogWrapper
            .find('button.sv-dialog-btn-primary')
            .prop('disabled', false);
      },

      disableSubmitButton: function() {
         this.$dialogWrapper
            .find('button.sv-dialog-btn-primary')
            .prop('disabled', true);
      },

      bindEvents: function() {
         this.$dialogWrapper
            .off('click.sv-dialog.close')
            .on('click.sv-dialog.close', '.sv-dialog-close', $.proxy(this.close, this));
      },

      setURL: function(url) {
         this.options.url = url;
      }
   };

   $.fn[pluginName] = function (options, value) {
      return this.each(function() {
         var plugin = $.data(this, 'plugin_' + pluginName);
         if (!plugin) {
            $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
         } else {
            if (typeof options === 'string') {
               plugin[options](value);
            }
         }
      });
   };

})(window.$svjq, window, document);
