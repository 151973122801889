/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';
import '../util/slideShow/sv-imgSlideshow'; // Used when content list is used in carousel mode

$('.sv-contentlist-portlet').each((a, portlet) => {
  const $portlet = $(portlet);
  const tab = $portlet.find('.sv-tab');

  if (tab.length) {
    import(/* webpackChunkName: "tab-plugins" */ './tabPlugins');
  }
});
