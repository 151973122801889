/**
 * Copyright (C) SiteVision 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import $ from '@sv/jquery';
import { i18n, Ajax as ajax } from '@sv/util';
import { getEditApiUri } from '../util/portletUtil';

$('.sv-blog-portlet').on('click', '.sv-create-blog-entry', function () {
  var $this = $(this),
    archiveId = $this.data('archive-id'),
    templateId = $this.data('template-id'),
    container = $this.parent(),
    loadingInfo = $('<div>')
      .addClass('sv-loading-icon')
      .text(i18n.getText('portlet.blog.blog', 'creatingBlogEntry'));

  container.empty().append(loadingInfo);

  ajax.doPost({
    url: getEditApiUri({
      objectId: archiveId,
      contextNodeId: archiveId,
      modelHandler: 'article',
    }),
    data: {
      template: templateId,
      portletType: 'blog',
    },
    success: function (result) {
      window.location.href = '/edit/' + result.nodeId;
    },
  });

  return false;
});
