/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author robin
 */
import $ from '@sv/jquery';

const $resizableImages = $('a.sv-resizable-img');

function initResizableImages($resizableImages) {
  $resizableImages.fancybox({
    hideOnContentClick: true,
    helpers: { title: null },
    transitionIn: 'elastic',
    transitionOut: 'elastic',
    padding: 0,
    overlayOpacity: 0.6,
  });
}

if ($resizableImages.length) {
  import(/* webpackChunkName: "fancybox" */ '../vendor/fancybox').then(() => {
    initResizableImages($resizableImages);
  });
}

