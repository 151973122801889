((doc) => {
  const setup = () => {
    if (doc.querySelector('.frc-captcha')) {
      import(/* webpackChunkName: "friendly-c" */ 'friendly-challenge/widget');
    }
  };

  if (doc.readyState !== 'loading') {
    setup();
  } else {
    doc.addEventListener('DOMContentLoaded', setup);
  }
})(document);
