/**
* Copyright (C) SiteVision AB 2002-2020, all rights reserved
*
* @author albin
*/
import $ from '@sv/jquery';

$('body').on('click', 'a[rel*="external"]', function() {
  const link = $(this);

  if (link.is('.sv-editing-mode .sv-text-portlet-content *')) {
    return;
  }
  if (link.attr('href')) {
    link.attr('target', '_blank');
  }
});