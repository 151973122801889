/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Sjöstrand
 */
import $ from '@sv/jquery';

$('.sv-rating-portlet').each(function () {
  import(/* webpackChunkName: "rating-plugins" */ './plugins').then(() => {
    var $this = $(this);

    $this.find('.sv-rating-voted input').rating();
    $this.find('.sv-rating-voted input').rating('disable');

    $this.find('.sv-rating-labelcontainer').css('display', 'none');
    $this.find('.sv-rating-submit').css('display', 'none');

    $this.find('.sv-star-container input[type=radio]').rating({
      callback: function () {
        var $form = $(this).parents('form'),
          formData = $form.serialize(),
          url = $form.data('ajax-posturl');

        $.post(url, formData, function (data) {
          var container = $form.closest('.sv-rating-portlet');

          $form.remove();
          container.append(data);
        });
      },
    });

    $this.find('.sv-thumb-rating input').on('click keypress', function (e) {
      e.preventDefault();
      var $el = $(this),
        $form = $el.closest('form'),
        value = $el.data('value') ? $el.data('value') : $el.attr('value'),
        formData = $form.serialize() + '&ratingValue=' + value,
        url = $form.data('ajax-posturl');

      $.post(url, formData, function (data) {
        var container = $form.closest('.sv-rating-portlet');

        $form.remove();
        container.append(data);
      });
    });
  });
});
