/**
 * Copyright (C) SiteVision 2002-2020, all rights reserved
 *
 * @author Ola Vognild
 */

import $ from '@sv/jquery';
import sv from '@sv/core';

const $sortableContainers = $('.sv-text-portlet .sv-tablesorter');

if ($sortableContainers.length) {
  import(/* webpackChunkName: "tablesorter-plugins" */ './plugins').then(() => {
    sv.sortableTables = sv.sortableTables || {};
    sv.sortableTables.dateFormat = sv.sortableTables.dateFormat || 'ddmmyyyy';

    $sortableContainers.tablesorter({
      dateFormat: sv.sortableTables.dateFormat,
    });
  });
}
